import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap';

export default (props) => {
  const {data, location} = props;
  return (
    <Layout data={data} location={location}>
      <h1 className='text-center m-5'>{data.markdownRemark.frontmatter.title}</h1>
      <div className="row blog-content mt-3 mb-5">
        {data.markdownRemark.frontmatter.tool_list && data.markdownRemark.frontmatter.tool_list.map((tool, index) => {
          return (
            <a href='/tools/calculator/' className="blog-item d-flex col-md-4" key={index}> 
              <div className="card mt-3 text-center" style={{ width: '-webkit-fill-available' }}>
              <i className={`fas fa-${tool.icon} p-5`} style={{ fontSize: '150px' }}></i>
                <div className="card-body">
                  <div className="blog-details">
                    <h5 className="card-text text-center pt-3">{tool.tool_name}</h5>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </Layout>
  );
}

export const toolQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        type
        template
        red_track
        featured_image
        description
        tool_list {
          tool_name
          icon

        }
      }
    }
  }
`;